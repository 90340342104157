import { defineStore } from "pinia";
import { ref, computed, reactive, watch } from "vue";
import { useOrganizations } from "./organizations";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { DataInstance, Organizations } from "@/platform-data";

declare global {
    interface Window { GoogleMapsApiInit: () => void; }
}

export const useGoogleMaps = defineStore('google-maps', () => {
    const organizations = useOrganizations();
    const initialized = ref(false);
    const mapsInitialized = computed(() => initialized.value);
    const waitingPromises = ref<(() => void)[]>([]);
    const selectedOrganization = ref<string|null>(null);
    const selectedOrgDetails =ref<DataInstance<Organizations.Model> | null>(null);

    const Lib = reactive({
        Maps: null as google.maps.MapsLibrary | null,
        Marker: null as google.maps.MarkerLibrary | null,
        Geocoding: null as google.maps.GeocodingLibrary | null,
        Core: null as google.maps.CoreLibrary | null,
        Places: null as google.maps.PlacesLibrary | null
    });

    function createMapScript() {
        const scriptElement = document.createElement('script');
        const scriptUrl = new URL('https://maps.googleapis.com/maps/api/js');
        scriptUrl.searchParams.append('callback', "GoogleMapsApiInit");
        scriptUrl.searchParams.append('key', CONFIG.google.maps.apiKey);
        scriptUrl.searchParams.append("loading", "async");

        scriptElement.src = scriptUrl.toString();
        scriptElement.async = true;
        scriptElement.defer = true;
        document.head.appendChild(scriptElement);
    };

    async function mapsInit() {
        try {
            Lib.Maps = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
            Lib.Marker = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
            Lib.Core = await google.maps.importLibrary("core") as google.maps.CoreLibrary;
            Lib.Places = await google.maps.importLibrary("places") as google.maps.PlacesLibrary;

            console.log("Google Maps libraries loaded");
            initialized.value = true;
            waitingPromises.value.forEach(res => res());
        } catch (error) {
            console.error("Error loading Google Maps libraries:", error);
        }
    }

    function waitForReady() {
        if (!initialized.value) return new Promise<void>(res => { waitingPromises.value.push(res) });
        else return Promise.resolve();
    }


    function useMap(el: HTMLElement) {
        const map = new google.maps.Map(el, {
            zoom: 2,
            center: { lat: 13.922043, lng: -37.859651 },
            streetViewControl: false,
            zoomControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            mapId: CONFIG.google.maps.mapId
        });

        addMarkers(map);
        return { map };
    }

    function setTitleInInfoWindow(title:string) {
        // Query for the gm-style-iw-chr div
        const iwChr = document.querySelector('.gm-style-iw-chr');
        
        if (iwChr) {
            // Create a title element
            const titleElement = document.createElement('div');
            titleElement.className = 'title'
            titleElement.innerText = title;
            titleElement.style.fontWeight = 'bold';
            titleElement.style.fontSize = '16px';
    
            // Insert the title before the close button
            iwChr.insertBefore(titleElement, iwChr.firstChild);
        }
    }

    function selectOrganization(orgName: string | null) {
        selectedOrganization.value = orgName;
    }

    function openOrganizationDetails(org: DataInstance<Organizations.Model>){
        selectedOrgDetails.value = org
    };

    function populateInfoWindow(org: Organizations.Model){
        let contentString = `<div id="content">`;
        if(org['HQ Street Address']){
            const tmp = `<p><strong>HQ Street Address:</strong> ${org['HQ Street Address']}</p>`
            contentString += tmp;
        }

        if(org['Office #']){
            const tmp = `<p><strong>Office #:</strong> ${org['Office #']}</p>`
            contentString += tmp;
        }

        if(org['Category']){
            const tmp = `<p><strong>Category:</strong> ${org['Category']}</p>`
            contentString += tmp;
        }

        if(org["NON PROFIT"] !== null ){
             const tmp = `<p><strong>Advocacy:</strong> ${org['NON PROFIT']}</p>`
        }
        const buttonId = `${org['Organization Name']}-btn`;
        const detailsId = `${org['Organization Name']}-details`
        const button = `<button id="${buttonId}" class="open-db-btn">Open in Database</button>`;
        const detailButton = `<button id="${detailsId}" class="detail-btn">View More Details</button>`;

        let buttonWrapper = `<div class="btn-wrapper">`;
        buttonWrapper += button;
        buttonWrapper += detailButton;
        buttonWrapper += '</div>';
        //contentString += button;
        contentString += buttonWrapper;
        contentString += '</div>';
        return contentString;
    }

    async function addMarkers(map: google.maps.Map) {
        const orgs = Object.values(organizations.organizations);
    
        // Prepare all markers asynchronously
        const markerPromises: Promise<google.maps.marker.AdvancedMarkerElement | null>[] = [];
    
         // Create a single infoWindow to reuse
        const infoWindow = new google.maps.InfoWindow();

        for (const org of orgs) {
            if (org.lat && org.lng) {
                // Create an img element for the custom SVG marker
                const svgElement = document.createElement('img');
                //svgElement.src = '../resources/marker.svg'; 
                svgElement.src = "https://storage.googleapis.com/als-public/web-widget/resources/marker.svg";
                svgElement.style.width = '40px'; 
                svgElement.style.height = '40px'; 

                const marker = new google.maps.marker.AdvancedMarkerElement({
                    position: { lat: org.lat, lng: org.lng }, // Use lat/lng from the organization
                    map: null, // Set to null initially
                    content: svgElement,
                })

                marker.addListener('click', () => {
                    const contentString = populateInfoWindow(org);
                    infoWindow.setContent(contentString);
                    infoWindow.open(map, marker);
                
                    google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
                        setTitleInInfoWindow(org['Organization Name']);
                
                        const buttonId = `${org['Organization Name']}-btn`;
                        const detailsId = `${org['Organization Name']}-details`
                        const openInDatabaseBtn = document.getElementById(buttonId);
                        const detailsBtn = document.getElementById(detailsId);

                        if (openInDatabaseBtn) {
                            openInDatabaseBtn.addEventListener('click', () => {
                                selectOrganization(org['Organization Name']);
                            });
                        }

                        if(detailsBtn){
                            detailsBtn.addEventListener('click', ()=>{
                                openOrganizationDetails(org);
                            });
                        }
                    });
                });
                

                
                markerPromises.push(
                    Promise.resolve(marker)
                );
            }
        }
    
        // Wait for all marker promises to resolve and filter out null values
        const resolvedMarkers = await Promise.all(markerPromises);
        const markers = resolvedMarkers.filter((marker): marker is google.maps.marker.AdvancedMarkerElement => marker !== null);
    
        if (markers.length > 0) {
            // Add a marker clusterer to manage the markers.
            new MarkerClusterer({
                map: map,
                markers: markers,
                renderer: {
                    render({ count, position }) {
                        const clusterElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                        clusterElement.setAttribute("width", "50");
                        clusterElement.setAttribute("height", "50");
                        clusterElement.setAttribute("viewBox", "0 0 50 50");
    
                        const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
                        circle.setAttribute("cx", "25");
                        circle.setAttribute("cy", "25");
                        circle.setAttribute("r", "22");
                        circle.setAttribute("stroke", "rgba(255,255,255, 0.82");
                        circle.setAttribute("stroke-width", "2");
                        circle.setAttribute("fill", "rgba(26, 97, 78, 0.82)");
    
                        clusterElement.appendChild(circle);
    
                        const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
                        text.setAttribute("x", "25");
                        text.setAttribute("y", "30");
                        text.setAttribute("fill", "rgba(255,255,255, 0.82");
                        text.setAttribute("text-anchor", "middle");
                        text.setAttribute("font-size", "14");
                        text.setAttribute("font-weight", "bold");
                        text.textContent = String(count);
    
                        clusterElement.appendChild(text);
    
                        return new google.maps.marker.AdvancedMarkerElement({
                            position,
                            map: map,
                            content: clusterElement,
                        });
                    },
                },
            });
    
            // Set the map property on all markers to make them visible
            markers.forEach(marker => marker.map = map);
            console.log("Markers added and clusterer initialized");
        } else {
            console.log("No markers to add");
        }
    }
    
	function setOrgDetails(org:DataInstance<Organizations.Model>){
        selectedOrgDetails.value = org;
    }

    window.GoogleMapsApiInit = mapsInit;
    createMapScript();

    return { waitForReady, useMap, mapsInitialized, Lib, selectedOrganization, selectOrganization, selectedOrgDetails, setOrgDetails };
});