import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { Organizations, DataInstance } from "../platform-data";
import { collection, onSnapshot, QuerySnapshot, query, where, getDocs, orderBy, limit, startAt, endAt } from "firebase/firestore";
import { firestore } from "@/util/firebase";
import Fuse from "fuse.js";

let fuse: Fuse<any> | null = null;
const allOrganizations = ref<any[]>([]);

export const useOrganizations = defineStore('organizations', () => {
  const _organizations = ref<Record<string, DataInstance<Organizations.Model>>>({});
  const organizations = computed(() => _organizations.value);

  const waitingResolves: (() => void)[] = [];

  const isReady = ref(false);

  const HQCountryOptions = computed(() => {
    const countrySet = new Set<string>();

    Object.values(organizations.value).forEach(org => {
      if (org["HQ Country"]) {
        countrySet.add(org["HQ Country"]);
      }
    });
    return Array.from(countrySet);
  });

  const HQStateOptions = computed(() => {
    const stateSet = new Set<string>();

    Object.values(organizations.value).forEach(org => {
      if (org["HQ State Or Province"]) {
        stateSet.add(org["HQ State Or Province"]);
      }
    });

    return Array.from(stateSet);
  });

  function setupListeners() {
    const orgCollection = collection(firestore, "organizations");

    onSnapshot(orgCollection, (snapshot: QuerySnapshot) => {
      const updatedOrganizations: Record<string, DataInstance<Organizations.Model>> = {};

      snapshot.forEach(doc => {
        updatedOrganizations[doc.id] = {
          $id: doc.id,
          ...(doc.data() as Organizations.Model),
        };
      });

      _organizations.value = updatedOrganizations;
      isReady.value = true;

      while (waitingResolves.length > 0) {
        const resolve = waitingResolves.shift();
        if (resolve) resolve();
      }

    }, (error) => {
      console.error("Error fetching organizations:", error);
      isReady.value = true;

      while (waitingResolves.length > 0) {
        const resolve = waitingResolves.shift();
        if (resolve) resolve();
      }
    });
  }

  const waitForReady = async () => {
    if (isReady.value) {
      console.log("Organizations ready!");
      return true;
    } else {
      return new Promise<void>(resolve => waitingResolves.push(resolve));
    }
  };

  async function loadOrganizations() {
    const orgsRef = collection(firestore, "organizations");
    const snapshot = await getDocs(orgsRef);

    allOrganizations.value = snapshot.docs.map(doc => ({
      id: doc.id,
      name: doc.data()["Organization Name"].toLowerCase(),
      lat: doc.data().lat,
      lng: doc.data().lng
    }));

    updateSearchIndex();
  }

  function updateSearchIndex() {
    if (allOrganizations.value.length > 0) {
      fuse = new Fuse(allOrganizations.value, {
        keys: ["name"],
        threshold: 0.3,
        includeScore: true,
        shouldSort: true,
      });
    }
  }

  async function searchOrganizations(query: string) {
    if (!fuse || !query) return [];

    query = query.toLowerCase().trim();
    const results = fuse.search(query);

    return results.map(result => result.item);
  }

  setupListeners();

  return {
    organizations, isReady, HQCountryOptions, HQStateOptions, waitForReady, searchOrganizations, loadOrganizations
  };
});