import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { Organizations, DataInstance } from "../platform-data";
import { collection, onSnapshot, QuerySnapshot } from "firebase/firestore";
import { firestore } from "@/util/firebase";

export const useOrganizations = defineStore('organizations', () => {
  const _organizations = ref<Record<string, DataInstance<Organizations.Model>>>({});
  const organizations = computed(() => _organizations.value);

  const waitingResolves: (() => void)[] = [];

  const isReady = ref(false);

  const HQCountryOptions = computed(() => {
    const countrySet = new Set<string>();

    Object.values(organizations.value).forEach(org => {
      if (org["HQ Country"]) {
        countrySet.add(org["HQ Country"]);
      }
    });
    return Array.from(countrySet);
  });

  const HQStateOptions = computed(() => {
    const stateSet = new Set<string>();

    Object.values(organizations.value).forEach(org => {
      if (org["HQ State Or Province"]) {
        stateSet.add(org["HQ State Or Province"]);
      }
    });
    
    return Array.from(stateSet);
  });

  function setupListeners() {
    const orgCollection = collection(firestore, "organizations");

    onSnapshot(orgCollection, (snapshot: QuerySnapshot) => {
      const updatedOrganizations: Record<string, DataInstance<Organizations.Model>> = {};

      snapshot.forEach(doc => {
        updatedOrganizations[doc.id] = {
          $id: doc.id,
          ...(doc.data() as Organizations.Model),
        };
      });

      _organizations.value = updatedOrganizations;
      isReady.value = true;

      // Resolve all waiting promises
      while (waitingResolves.length > 0) {
        const resolve = waitingResolves.shift();
        if (resolve) resolve();
      }

    }, (error) => {
      console.error("Error fetching organizations:", error);
      isReady.value = true;

      // Resolve all waiting promises in case of error
      while (waitingResolves.length > 0) {
        const resolve = waitingResolves.shift();
        if (resolve) resolve();
      }
    });
  }

  const waitForReady = async () => {
    if (isReady.value) {
      console.log("Organizations ready!");
      return true;
    } else {
      return new Promise<void>(resolve => waitingResolves.push(resolve));
    }
  };

  setupListeners();

  return {
    organizations, isReady, HQCountryOptions, HQStateOptions, waitForReady
  };
});
