import { App, createApp } from "vue";
import AppComponent from "./widget/widget.vue";
import SampleWidget from "./widget/sample-widget.vue";
import "./util/globals";


let app:App<Element>;
if(NODE_APP_INSTANCE == "sample") {
    app = createApp(SampleWidget);
} else {
    app = createApp(AppComponent);
}

export {app};
export default app;
