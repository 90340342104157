import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e69ac7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "widget testing-layout-hint" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.state === 'login')
      ? (_openBlock(), _createBlock($setup["Auth"], { key: 0 }))
      : ($setup.state === 'database')
        ? (_openBlock(), _createBlock($setup["DatabaseTool"], {
            key: 1,
            onMoveToLogin: $setup.moveToLogin,
            "is-mobile": $setup.isMobile
          }, null, 8, ["is-mobile"]))
        : ($setup.state === 'map')
          ? (_openBlock(), _createBlock($setup["MapTool"], {
              key: 2,
              onMoveToLogin: $setup.moveToLogin
            }))
          : ($setup.state === 'comparison')
            ? (_openBlock(), _createBlock($setup["ComparisonTool"], {
                key: 3,
                onMoveToLogin: $setup.moveToLogin
              }))
            : _createCommentVNode("", true),
    ($setup.showDetailedView)
      ? (_openBlock(), _createBlock($setup["DetailedView"], {
          key: 4,
          organization: $setup.gMaps.selectedOrgDetails,
          state: $setup.state,
          onClose: $setup.closeDetailedView
        }, null, 8, ["organization", "state"]))
      : _createCommentVNode("", true)
  ]))
}