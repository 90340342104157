import { defineStore } from "pinia";
import { onAuthStateChanged, User, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut as logOut, sendPasswordResetEmail, ParsedToken, getIdTokenResult } from "firebase/auth";
import { auth } from "@/util/firebase";
import { computed, ref } from "vue";
import { FirebaseError } from "@firebase/util";
import { analytics  } from "../util/firebase";
import { logEvent } from "firebase/analytics";

const AuthErrorMap: {[code: string]: string} = {
    'auth/invalid-email': "Invalid Email",
    'auth/user-not-found': "User Not Found",
    'auth/wrong-password': "Password Invalid",
    'auth/email-already-in-use': "Email Already In Use"
};

function ConvertAuthError(errorCode: string) {
    // return AuthErrorMap[errorCode] || "Unknown"
    return AuthErrorMap[errorCode] || errorCode;
}

export const useAuth = defineStore('auth', ()=>{
	const user = ref<User | null>(null);
	const claims = ref<ParsedToken | null>(null);
	const _authready = ref<boolean>(false);
	const authUID = ref<string | null>(null);

	const waitingResolves: (() => void)[] = [];

	onAuthStateChanged(auth, async userAuth =>{
		user.value = userAuth;
		if(userAuth){
			claims.value = (await getIdTokenResult(userAuth)).claims;
			authUID.value = userAuth.uid;
		}else{
			claims.value = null;
			authUID.value = null
		};
		_authready.value = true;
		console.log("Auth State Changed", user.value !== null ? "Authenticated" : "Unauthenticated");
		waitingResolves.forEach(res => res());
        waitingResolves.length = 0;
	})

	const waitForReady = () => {
        if(_authready.value) { /* empty */ }
        else {
            return new Promise<void>(res => {waitingResolves.push(res);});
        }
    };

	const isAuthReady = computed(() => _authready.value);
    const isAuthenticated = computed(() => user.value !== null);

	async function signIn(email: string, password: string) {
        try {
            const creds = await signInWithEmailAndPassword(auth, email, password);
            user.value = creds.user;
            logEvent(analytics, "login",{
                method: "Email"
            });
            return { success: true };
        } catch (e) {
            console.error(e);
            return { success: false, reason: ConvertAuthError((e as FirebaseError).code) };
        }
    }

	async function createUser(email: string, password: string) {
        try {
            const creds = await createUserWithEmailAndPassword(auth, email, password);
            user.value = creds.user;
            logEvent(analytics, "signup",{
                method: "Email"
            });
            return { success: true };
        } catch (e) {
            console.error(e);
            return { success: false, reason: ConvertAuthError((e as FirebaseError).code) };
        }
    }

	function sendPasswordReset(email: string) {
        return sendPasswordResetEmail(auth, email);
    }

    async function signOut() {
        try {
            await logOut(auth);
            logEvent(analytics, "signout");
            localStorage.clear();
        } catch (e) {
            console.error(e);
        }
    }

	async function reloadClaims() {
        const token = await user.value?.getIdTokenResult(true);
        claims.value = token?.claims || null;
    }

	return { waitForReady, isAuthReady, signIn, createUser, signOut, user}

})