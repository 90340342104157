import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { id: "map-tool" }
const _hoisted_2 = { class: "map-container" }
const _hoisted_3 = {
  key: 0,
  class: "suggestions"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { ref: "mapContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        class: "search-bar",
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchQuery) = $event)),
        placeholder: "Search organizations...",
        onInput: $setup.fetchSuggestions,
        onFocus: _cache[1] || (_cache[1] = ($event: any) => ($setup.showSuggestions = true)),
        onBlur: $setup.hideSuggestions
      }, null, 544), [
        [_vModelText, $setup.searchQuery]
      ]),
      ($setup.showSuggestions && $setup.searchResults.length)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.searchResults, (org) => {
              return (_openBlock(), _createElementBlock("li", {
                key: org.id,
                onClick: ($event: any) => ($setup.selectSuggestion(org))
              }, _toDisplayString(org.name), 9, _hoisted_4))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      ($setup.auth.user === null)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "login-scrim",
            onClick: $setup.moveToLogin
          }))
        : _createCommentVNode("", true),
      _createElementVNode("article", _hoisted_5, null, 512),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}