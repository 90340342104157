import "core-js/stable";
import "regenerator-runtime/runtime";
import widget from "./widget";
import { createPinia } from "pinia";
import {resolveTargetElement} from "./snippet-util";
import "./stylesheets/app.scss";

console.log("Widget loading...");

// Enable if you need firebase
// import "@/util/firebase";

const pinia = createPinia();

import { initRaygun } from "@busy-human/raygun-util";
if(!CONFIG.raygun.apiKey) {
    console.warn("raygun apikey not set");
} else {
    initRaygun(CONFIG.raygun.apiKey, { Vue: widget, version: VERSION, environment: ENVIRONMENT});
}

widget.use(pinia)
widget.mount( resolveTargetElement() );
