import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  class: "grid",
  id: "x-axis"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["value", "checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.selectedColumns, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.label,
        class: "checkbox row i-undefined",
        onClick: ($event: any) => ($setup.handleClick(item))
      }, [
        _createElementVNode("input", {
          type: "checkbox",
          value: item.value,
          checked: item.value
        }, null, 8, _hoisted_3),
        _createElementVNode("label", null, _toDisplayString(item.label), 1)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}