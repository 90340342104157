import { type Organizations, type DataInstance } from "@/platform-data";

export type Item = {label: string, value: boolean, k: string, column: keyof DataInstance<Organizations.Model>}
export type XAxisType = Item[];
export interface SelectedFilters {
	[key: string | symbol]: string[];
	Country: string[];
	'State/Province': string[];
	ProfitType: string[];
	Focus: string[];
	ServicesOrResources: string[];
	SupportGroups: string[];
	FundsOrGrants: string[];
}

export const xAxis:XAxisType = [
	{k: "budget",    value: false, column: "Annual Budget",       label: "Annual Budget",          },
	{k: "employees", value:  true, column: "# of Employees",      label: "Employee Count",         },
	{k: "facebook",  value:  true, column: "Facebook Page Likes", label: "Facebook Likes",         },
	{k: "instagram", value:  true, column: "Instagram Followers", label: "Instagram Followers",    },
	{k: "followers", value:  true, column: "Number of Followers", label: "Social Media Followers", },
	{k: "twitter",   value:  true, column: "Twitter Followers",   label: "Twitter Followers",      },
	{k: "youtube",   value:  true, column: "Youtube Subscribers", label: "YouTube Subscribers",    },
];

export const colors = [
	"#E0BD52",
	"#709DDB",
	"#E25A7E",
	"#99D65C",
	"#AF70DB",
	"#5CD6B8",
];

export const filtersBase = {
	Country: [],
	'State/Province': [],
	ProfitType: [],
	Focus: [],
	ServicesOrResources: [],
	SupportGroups: [],
	FundsOrGrants: []
}

export function sterilizeStrings(string: string) {
	//
	let x = string.replace(/([\.\s].*)/g, "")
		// Remove the following string(s):
		.replace("no known employees, ", "")
		// Turn "8k" to "8000"
		.replace(/\dk/g, "000")
		// Remove everything after a decimal point // example $1,000.00 and 7 volunteers => $1,000
		.replace(/([\.\s].*)/g, "")
		// Remove anything that is not a digit "$1,000" => "1000"
		.replace(/\D/g, "")
	// If empty string, turn to "0"
	x = !x.length ? "0" : x;

	// If string can be turned into Integer, parse to integer:
	let xNumber = isNaN(parseInt(x, 10)) ? x : parseInt(x, 10);
    return xNumber
}

export const prettifyURLs = (urlString: string) => {
	/* remove "http(s)://",  remove anything after something like ".org.za" or ".com" */
	let str = urlString.replace(/https?:\/\/|(\.([a-z]{2,}\.[a-z]{2,}|com|org|gov|net|se)).*/g, '$1');
	return str;
}

export function longNameClass( orgName: string){
	let nameLength = orgName.length;
	if(nameLength < 30) return;
	let className = "";
	className = `lines-${Math.round(nameLength/35)}`
	return className;
}

export type Organization = DataInstance<Organizations.Model>;
export type OrgsList = Organization[];

export function filterOrganizations(selectedFilters: SelectedFilters, orgsList: OrgsList){
	let filteringList = Object.values(orgsList);

	const countrySet = new Set(selectedFilters.Country);
	const stateSet = new Set(selectedFilters['State/Province']);
	const profitTypeSet = new Set(selectedFilters.ProfitType);
	const focusSet = new Set(selectedFilters.Focus);
	const servicesOrResourcesSet = new Set(selectedFilters.ServicesOrResources);
	const supportGroupsSet = new Set(selectedFilters.SupportGroups);
	const fundsOrGrantsSet = new Set(selectedFilters.FundsOrGrants);

	let filteredList = filteringList.filter(org => {
		// Combine country and state match into a single OR condition
		const locationMatch =
			!countrySet.size && !stateSet.size ||
			(org['HQ Country'] && countrySet.has(org['HQ Country'])) ||
			(org['HQ State Or Province'] && stateSet.has(org['HQ State Or Province']));

		const profitTypeMatch = !profitTypeSet.size ||
			(profitTypeSet.has('For Profit') && org['NON PROFIT'] === false) ||
			(profitTypeSet.has('Non-Profit') && org['NON PROFIT'] === true);

		const focusMatch = !focusSet.size ||
			focusSet.has('Non ALS Focused') && org['NON ALS FOCUSED'] ||
			focusSet.has('Related Disease Focus') && org['RELATED DISEASE'] ||
			focusSet.has('Primarily ALS Focused') && org['PRIMARILY ALS FOCUSED'];

		const servicesOrResourcesMatch = !servicesOrResourcesSet.size ||
			servicesOrResourcesSet.has('Raise Awareness') && org['ALS AWARENESS'] ||
			servicesOrResourcesSet.has('Conducts Research') && org['CONDUCTS ALS RESEARCH'] ||
			servicesOrResourcesSet.has('Funds ALS Research') && org['FUNDS ALS RESEARCH'] ||
			servicesOrResourcesSet.has('Consortia') && org['CONSORTIA'] ||
			servicesOrResourcesSet.has('Online Information') && org['ONLINE INFO SOURCE'];

		const supportGroupsMatch = !supportGroupsSet.size ||
			(supportGroupsSet.has('Online') && org['ONLINE SUPPORT GROUPS']) ||
			(supportGroupsSet.has('In Person') && org['IN PERSON SUPPORT GROUPS']);

		const fundsOrGrantsMatch = !fundsOrGrantsSet.size ||
			fundsOrGrantsSet.has('Home Care') && org['FUNDS HOME CARE'] ||
			fundsOrGrantsSet.has('Adaptive Tech') && org['ADAPTIVE TECH OEM'] ||
			fundsOrGrantsSet.has('Research') && org['FUNDS ALS RESEARCH'];

		return locationMatch && profitTypeMatch && focusMatch && servicesOrResourcesMatch && supportGroupsMatch && fundsOrGrantsMatch;
	});

	return filteredList;

}